<template>
    <a class="button">
        <span class="button__glow">
            <span></span>
        </span>
        <span class="button__inner">
            <span>
                <slot></slot>
            </span>
        </span>
    </a>
</template>